import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './navigation';
import axiosInstance from '../../helpers/axios-instance';

export default function Contracts() {
  const [contracts, setContracts] = useState([]);

  const getContracts = async () => {
    try {
      const response = await axiosInstance.get('/contracts');
      setContracts(response.data.contracts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // get the all the courses.
    getContracts();
  }, []);

  return (
    <>
      <div className="bg-gray-600">
        <div className="max-w-7xl mx-auto p-4 md:p-8">
          <h1 className="uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight">
            My <span className="font-extrabold">Contracts</span>
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-8 md:flex">
        <Navigation />
        <section className="mt-4 md:mt-0 md:w-3/4">
          <div className="border-b border-gray-200 pb-5 mb-6 sm:mb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-200">Contracts</h3>
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-300">List of pending and completed contracts</p>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-800">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b border-gray-700 bg-gray-700 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 border-b border-gray-700 bg-gray-700 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 border-b border-gray-700 bg-gray-700 text-center text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider" />
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {contracts.map((contract) => (
                      <tr className="bg-gray-900">
                        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-700 text-sm leading-5 font-medium text-gray-200">
                          <i className="far fa-calendar-alt text-gray-400 mr-2" aria-hidden="true" />
                          {contract.createdAt}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap border-b border-gray-700 text-sm leading-5 text-gray-200">
                          {contract.status}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center border-b border-gray-700 flex justify-center text-sm leading-5 font-medium">
                          {contract.status === 'complete' ? (
                            <a
                              rel="noreferrer"
                              target="blank"
                              className="text-blue-opex hover:text-blue-darker focus:outline-none focus:underline"
                              href={contract.pdfUrl}
                            >
                              Download
                            </a>
                          ) : (
                            <Link
                              to={`/account/contracts/${contract.id}`}
                              className="text-blue-opex hover:text-blue-darker focus:outline-none focus:underline"
                            >
                              Sign
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
