/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import Cookies from 'js-cookie';
import { useAuth } from '../../context/AuthContext';
import SignupFree from '../signupFree';

export default function ExitIntent() {
  const [open, setOpen] = useState(false);
  const { isAuth } = useAuth();
  const timer = useRef(null);

  const mouseEvent = (e) => {
    const shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10 && !isAuth;

    if (shouldShowExitIntent) {
      document.removeEventListener('mouseout', mouseEvent);
      setOpen(true);
      Cookies.set('exitIntentShown', true, { expires: 5 });
    }
  };

  useEffect(() => {
    if (!Cookies.get('exitIntentShown') && !isAuth) {
      timer.current = setTimeout(() => {
        document.addEventListener('mouseout', mouseEvent);
      }, 5000);
    } else {
      document.removeEventListener('mouseout', mouseEvent);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }
  }, [isAuth]);

  const closeModal = () => {
    setOpen(false);
  };

  return ReactDom.createPortal(<SignupFree open={open} onClose={closeModal} />, document.body);
}
