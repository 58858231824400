import axiosInstance from './axios-instance';

const Authenticate = async () => {
  let auth = { isAuth: false, user: {} };
  await axiosInstance
    .get(`/logged_in`)
    .then((response) => {
      auth = { isAuth: response.data.logged_in, user: response.data.user };
      return auth;
    })
    .catch((err) => console.log(err));
  return auth;
};

export default Authenticate;
