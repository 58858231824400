import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/solid';
import Navigation from './navigation';
import axiosInstance from '../../helpers/axios-instance';

export default function Password() {
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const patchPassword = async (data) => {
    try {
      await axiosInstance({
        method: 'patch',
        url: `/change_password`,
        data: {
          ...data
        }
      });
      setSuccess(true);
      setError(null);
      reset();
    } catch (error) {
      setSuccess(null);
      setError(error);
      reset();
    }
  };

  const passwordSubmit = async (data) => {
    console.log(data);
    await patchPassword(data);
  };

  return (
    <>
      <div className="bg-gray-600">
        <div className="max-w-7xl mx-auto p-4 md:p-8">
          <h1 className="uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight">
            Change <span className="font-extrabold">Password</span>
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-8 md:flex">
        <Navigation />
        <section className="mt-4 md:mt-0 md:w-3/4">
          {success && (
            <div className="rounded-md bg-green-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">Successfully updated</p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                      onClick={() => setSuccess(null)}
                    >
                      <span className="sr-only">Dismiss</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {error && (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">There were an error updating your account</h3>
                </div>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(passwordSubmit)} className="bg-gray-800 shadow sm:rounded-lg p-6">
            <div>
              <div>
                <div>
                  <h2
                    id="payment_details_heading"
                    className="text-lg leading-6 font-bold text-gray-200"
                  >
                    Change Password
                  </h2>
                  <p className="mt-1 text-sm leading-5 text-gray-200 ">
                    Update your account password. Please note you must know your old password to change it.
                  </p>
                </div>

                <div className="mt-4 grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="current_password" className="block text-sm font-medium leading-5 text-gray-200">
                      Old password
                      <input
                        {...register('old_password', { required: true })}
                        type="password"
                        name="old_password"
                        className="text-gray-700 form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <p className="text-red-500">{errors.old_password?.type === 'required' && 'Your current password is required'}</p>
                    </label>
                  </div>

                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-200">
                      New password
                      <input
                        {...register('password', { required: true })}
                        type="password"
                        name="password"
                        className="text-gray-700 form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                      <p className="text-red-500">{errors.password?.type === 'required' && 'Your new password is required'}</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="bg-blue-opex border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm leading-5 font-medium text-white hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out"
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
