import React, { useEffect } from 'react';

export default function Form() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = true;

    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: process.env.REACT_APP_HUBSPOT_ACCOUNT,
          formId: '73f26801-0c71-4bd8-9a5d-e7e2dec6ac88',
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return <div id="hubspotForm" />;
}
