import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ComingSoon from '../images/coming-soon.png';
import { PrevArrow, NextArrow } from './arrows';

export default function Carousel({
  title,
  description,
  classes,
  type,
  link,
  noSpacing,
  defer,
}) {
  const settings = {
    infinite: false,
    slidesToShow: 4,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let items = null;

  function AddMissingItems() {
    if (items.length < 4) {
      for (let i = items.length; i < 4; i += 1) {
        items.push(
          <div key={i} className='p-2'>
            <img src={ComingSoon} alt='Coming Soon' />
          </div>
        );
      }
    }
  }

  if (type === 'course') {
    items = classes.map((masterClass) => (
      <div key={masterClass.id} className='p-2 col-span-1'>
        <Link to={`/courses/${masterClass.slug}`}>
          {masterClass.thumbnail_image ? (
            <img
              width='363'
              height='204'
              loading={defer ? 'lazy' : 'eager'}
              src={masterClass.thumbnail_image}
              alt='course'
            />
          ) : (
            <img
              width='363'
              height='204'
              loading={defer ? 'lazy' : 'eager'}
              src='https://ucarecdn.com/20b2bdca-861e-4d15-932c-07bedb612864/'
              alt='course'
            />
          )}
        </Link>
        <h5 className='mt-2 text-white font-medium'>{masterClass.title}</h5>
        <p className='text-gray-300 text-sm'>
          {masterClass.instructor.first_name} {masterClass.instructor.last_name}
        </p>
      </div>
    ));
  } else if (type === 'class') {
    items = classes.map((masterClass) => (
      <div key={masterClass.id} className='p-2 col-span-1'>
        <Link to={`/classes/${masterClass.slug}`}>
          {masterClass.thumbnail_image ? (
            <img
              width='363'
              height='204'
              loading={defer ? 'lazy' : 'eager'}
              src={masterClass.thumbnail_image}
              alt='course'
            />
          ) : (
            <img
              width='363'
              height='204'
              loading={defer ? 'lazy' : 'eager'}
              src='https://ucarecdn.com/20b2bdca-861e-4d15-932c-07bedb612864/'
              alt='course'
            />
          )}
        </Link>
        <h5 className='mt-2 text-white font-medium'>{masterClass.title}</h5>
        <p className='text-gray-300 text-sm'>
          {masterClass.instructors?.map((instructor, index) => (
            <span key={instructor.id}>
              {index > 0 && ', '}
              {instructor.name}
            </span>
          ))}
        </p>
      </div>
    ));
  } else {
    items = classes.map((masterClass) => (
      <div key={masterClass.id} className='p-2 col-span-1'>
        <Link to={`/classes/${masterClass.slug}`}>
          <img
            width='363'
            height='204'
            loading={defer ? 'lazy' : 'eager'}
            src='https://ucarecdn.com/20b2bdca-861e-4d15-932c-07bedb612864/'
            alt='course'
          />
        </Link>
        <h5 className='mt-2 text-white font-medium'>{masterClass.title}</h5>
        <p className='text-gray-300 text-sm'>
          {masterClass.instructor.first_name} {masterClass.instructor.last_name}
        </p>
      </div>
    ));
  }

  return (
    <div className={`${noSpacing ? '' : 'container'} py-8`}>
      {link ? (
        <Link to={link}>
          <h4 className='carousel-title flex inline-flex items-center uppercase font-bold text-xl text-blue-opex'>
            {title}
            <span className='flex items-center'>
              <span className='see-all-link'>Explore All</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M9 5l7 7-7 7'
                />
              </svg>
            </span>
          </h4>
        </Link>
      ) : (
        <h4 className='uppercase font-bold text-xl text-blue-opex'>{title}</h4>
      )}
      {description && <p>{description}</p>}
      <div>
        {items.length > 4 ? (
          <Slider {...settings}>{items}</Slider>
        ) : (
          <div className='grid grid-cols-2 md:grid-cols-4'>{items}</div>
        )}
      </div>
    </div>
  );
}

Carousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  classes: PropTypes.array,
  type: PropTypes.string,
  link: PropTypes.string,
  noSpacing: PropTypes.bool,
  defer: PropTypes.bool,
};
