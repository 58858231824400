import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Navigation from './navigation';
import axiosInstance from '../../helpers/axios-instance';
import UpdateCard from './update-card';
import { useAuth } from '../../context/AuthContext';
import CancelConfirm from './cancel-confirm';
import Upgrade from './upgrade';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

export default function Billing() {
  const [card, setCard] = useState({});
  const [charges, setCharges] = useState([]);
  const { isAuth, user, setUser } = useAuth();
  const [showCardForm, setShowCardForm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      try {
        const response = await axiosInstance.get(`/billing`);
        if (!didCancel) {
          setCard(response.data.defaultCard);
          setCharges(response.data.charges);
        }
      } catch (error) {}
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, []);

  async function addCard(setupIntent) {
    try {
      const response = await axiosInstance.post(`/cards`, { setupIntent });
      setCard(response.data.defaultCard);
    } catch (error) {}
  }

  async function reactivate() {
    try {
      const response = await axiosInstance.patch(`/subscriptions/reactivate`);
      setUser(response.data.user);
      setShowCancelConfirm(false);
    } catch (error) {}
  }

  const updateCardHandler = (setupIntent) => {
    addCard(setupIntent);
    setShowCardForm(false);
  };

  const reactivateSubscription = () => {
    reactivate();

    console.log('cancel subscription');
  };

  return (
    <>
      <div className='bg-gray-600'>
        <div className='max-w-7xl mx-auto p-4 md:p-8'>
          <h1 className='uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight'>
            Manage <span className='font-extrabold'>Subscriptions</span>
          </h1>
        </div>
      </div>
      <div className='max-w-7xl mx-auto py-8 md:flex'>
        <Navigation />
        <div className='mt-4 md:mt-0 md:w-3/4'>
          <div className='mb-4 p-6 bg-gray-800 shadow sm:rounded-lg'>
            <div className='flex justify-between'>
              <h3 className='text-lg leading-6 font-medium text-gray-200'>
                Payment method
              </h3>
              <a
                href='mailto:support@opexfit.com'
                className='text-blue-opex uppercase leading-5 font-medium border border-blue-opex px-4 py-2 rounded-md hover:text-white hover:bg-blue-darker'
              >
                Help
              </a>
            </div>
            <div className='mt-5'>
              {card ? (
                <div className='rounded-md bg-gray-700 px-6 py-5 sm:flex sm:items-start sm:justify-between'>
                  <div className='sm:flex sm:items-start'>
                    <svg
                      aria-hidden='true'
                      focusable='false'
                      data-prefix='fas'
                      data-icon='credit-card-front'
                      className='svg-inline--fa fa-credit-card-front w-5 h-5'
                      role='img'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 576 512'
                    >
                      <path
                        fill='currentColor'
                        d='M512 32h-448c-35.35 0-64 28.65-64 64v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM63.1 272c0-8.838 7.164-16 16-16h96c8.836 0 16 7.162 16 16v32c0 8.836-7.164 16-16 16h-96c-8.836 0-16-7.164-16-16V272zM160 408C160 412.4 156.4 416 152 416h-80C67.58 416 64 412.4 64 408l0-16c0-4.42 3.582-8 8-8h80c4.418 0 8 3.58 8 8L160 408zM352 408c0 4.418-3.582 8-8 8h-144C195.6 416 192 412.4 192 408l0-16c0-4.42 3.582-8 8-8h143.1c4.418 0 8 3.58 8 8L352 408zM384 304c0 8.836-7.164 16-16 16h-128c-8.836 0-16-7.164-16-16v-32c0-8.838 7.164-16 15.1-16h128c8.836 0 16 7.162 16 16L384 304zM512 304c0 8.836-7.164 16-16 16h-64C423.2 320 416 312.8 416 304l0-32c0-8.838 7.164-16 16-16H496C504.8 256 512 263.2 512 272V304zM512 176C512 184.8 504.8 192 496 192h-96c-8.836 0-16-7.164-16-16v-64c0-8.838 7.164-16 16-16H496C504.8 96 512 103.2 512 112V176z'
                      />
                    </svg>
                    <div className='mt-3 sm:mt-0 sm:ml-4'>
                      <div className='text-sm leading-5 font-medium text-gray-200'>
                        Ending with {card.last4}
                      </div>
                      <div className='mt-1 text-sm leading-5 text-gray-200 sm:flex sm:items-center'>
                        <div>
                          Expires {card.expMonth}/{card.expYear}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
                    <span className='inline-flex rounded-md shadow-sm'>
                      <button
                        onClick={() => setShowCardForm(true)}
                        type='button'
                        className='uppercase inline-flex items-center px-4 py-2 border border-blue-darker hover:bg-blue-darker text-sm leading-5 font-medium rounded-md text-white bg-blue-opex focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-darker transition ease-in-out duration-150'
                      >
                        Edit
                      </button>
                    </span>
                  </div>
                </div>
              ) : (
                <div className='rounded-md bg-gray-700 px-6 py-5 sm:flex sm:items-start sm:justify-between'>
                  <div className='sm:flex sm:items-start'>
                    <i
                      className='fab fa-cc-visa text-2xl text-gray-700 sm:flex-shrink-0'
                      aria-hidden='true'
                    />
                    <div className='mt-3 sm:mt-0 sm:ml-4'>
                      <div className='text-base leading-5 font-medium text-gray-200'>
                        No card on file
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0'>
                    <span className='inline-flex rounded-md shadow-sm'>
                      <button
                        onClick={() => setShowCardForm(true)}
                        type='button'
                        className='uppercase inline-flex items-center px-4 py-2 border border-blue-darker text-sm leading-5 font-medium rounded-md text-white bg-blue-opex hover:bg-blue-darker focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-white active:bg-blue-darker transition ease-in-out duration-150'
                      >
                        Add Card
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {user.hasMembership ? (
            <div className='mb-4 p-6 bg-gray-800 shadow sm:rounded-lg'>
              <h3 className='text-lg leading-6 font-medium text-gray-200'>
                {user.cancelsAt
                  ? 'Reactivate your plan'
                  : 'Edit your subscription'}
              </h3>
              <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
                <div>
                  <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300 font-bold'>
                    {user.cancelsAt
                      ? `Subscription ends ${user.cancelsAt}`
                      : `Next billing date: ${user.nextInvoice}`}
                  </p>
                  <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300'>
                    Easily upgrade or change your LearnRx subscription.
                  </p>
                  <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300'>
                    For questions or support, you can contact us at{' '}
                    <a href='mailto:support@opexfit.com?subject=I%20have%20a%20question%20about%20my%20LearnRx%20account'>
                      support@opexfit.com
                    </a>{' '}
                    — we'd love to help.
                  </p>
                </div>
                <div className='rounded-md shadow-sm'>
                  {user.hasMembership && user.cancelsAt ? (
                    <Link
                      to='/account/edit-subscription'
                      className='oswald uppercase inline-flex items-center px-4 py-2 border border-blue-darker hover:bg-blue-darker text-sm leading-5 font-medium rounded-md text-white bg-blue-opex focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-darker transition ease-in-out duration-150'
                    >
                      Change Plan
                    </Link>
                  ) : (
                    <Link
                      to='/account/edit-subscription'
                      className='oswald uppercase inline-flex items-center px-4 py-2 border border-blue-darker hover:bg-blue-darker text-sm leading-5 font-medium rounded-md text-white bg-blue-opex focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-darker transition ease-in-out duration-150'
                    >
                      Change Plan
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className='mb-4 p-6 bg-gray-800 shadow sm:rounded-lg'>
              <h3 className='text-lg leading-6 font-medium text-gray-200'>
                Edit your subscription
              </h3>
              <div className='mt-2 sm:flex sm:items-start sm:justify-between'>
                <div>
                  <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300'>
                    Easily upgrade or change your LearnRx subscription.
                  </p>
                  <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300'>
                    For questions or support, you can contact us at{' '}
                    <a href='mailto:support@opexfit.com?subject=I%20have%20a%20question%20about%20my%20LearnRx%20account'>
                      support@opexfit.com
                    </a>{' '}
                    — we'd love to help.
                  </p>
                </div>
                <div className='rounded-md shadow-sm'>
                  <Link
                    to='/account/edit-subscription'
                    className='oswald uppercase inline-flex items-center px-4 py-2 border border-blue-darker hover:bg-blue-darker text-sm leading-5 font-medium rounded-md text-white bg-blue-opex focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-darker transition ease-in-out duration-150'
                  >
                    Change Plan
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className='mb-4 p-6 bg-gray-800 shadow sm:rounded-lg'>
            <div className='border-b border-gray-200 pb-5 mb-6 sm:mb-5'>
              <h3 className='text-lg leading-6 font-medium text-gray-200'>
                Payment history
              </h3>
              <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-300'>
                Thanks for being a subscriber. 🙏
              </p>
            </div>
            <div className='flex flex-col'>
              <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
                <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-800'>
                  <table className='min-w-full'>
                    <thead>
                      <tr>
                        <th className='px-6 py-3 border-b border-gray-700 bg-gray-700 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider'>
                          Date
                        </th>
                        <th className='px-6 py-3 border-b border-gray-700 bg-gray-700 text-left text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider'>
                          Amount
                        </th>
                        <th className='px-6 py-3 border-b border-gray-700 bg-gray-700 text-center text-xs leading-4 font-medium text-gray-200 uppercase tracking-wider'>
                          Receipt
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      {charges.map((charge) => (
                        <tr className='bg-gray-900'>
                          <td className='px-6 py-4 whitespace-nowrap border-b border-gray-700 text-sm leading-5 font-medium text-gray-200'>
                            <i
                              className='far fa-calendar-alt text-gray-400 mr-2'
                              aria-hidden='true'
                            />
                            {charge.date}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap border-b border-gray-700 text-sm leading-5 text-gray-200'>
                            {charge.amount} USD
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-center border-b border-gray-700 flex justify-center text-sm leading-5 font-medium'>
                            <a
                              rel='noreferrer'
                              target='blank'
                              className='text-blue-opex hover:text-blue-darker focus:outline-none focus:underline'
                              href={charge.receipt_url}
                            >
                              <svg
                                aria-hidden='true'
                                focusable='false'
                                data-prefix='fas'
                                data-icon='file-pdf'
                                className='svg-inline--fa fa-file-pdf w-4 h-4'
                                role='img'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 384 512'
                              >
                                <path
                                  fill='currentColor'
                                  d='M184 208c0-4.406-3.594-8-8-8S168 203.6 168 208c0 2.062 .2969 23.31 9.141 50.25C179.1 249.6 184 226.2 184 208zM256 0v128h128L256 0zM80 422.4c0 9.656 10.47 11.97 14.38 6.375C99.27 421.9 108.8 408 120.1 388.6c-14.22 7.969-27.25 17.31-38.02 28.31C80.75 418.3 80 420.3 80 422.4zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM292 312c24.26 0 44 19.74 44 44c0 24.67-18.94 44-43.13 44c-5.994 0-11.81-.9531-17.22-2.805c-20.06-6.758-38.38-15.96-54.55-27.39c-23.88 5.109-45.46 11.52-64.31 19.1c-14.43 26.31-27.63 46.15-36.37 58.41C112.1 457.8 100.8 464 87.94 464C65.92 464 48 446.1 48 424.1c0-11.92 3.74-21.82 11.18-29.51c16.18-16.52 37.37-30.99 63.02-43.05c11.75-22.83 21.94-46.04 30.33-69.14C136.2 242.4 136 208.4 136 208c0-22.05 17.95-40 40-40c22.06 0 40 17.95 40 40c0 24.1-7.227 55.75-8.938 62.63c-1.006 3.273-2.035 6.516-3.082 9.723c7.83 14.46 17.7 27.21 29.44 38.05C263.1 313.4 284.3 312.1 287.6 312H292zM156.5 354.6c17.98-6.5 36.13-11.44 52.92-15.19c-12.42-12.06-22.17-25.12-29.8-38.16C172.3 320.6 164.4 338.5 156.5 354.6zM292.9 368C299 368 304 363 304 356.9C304 349.4 298.6 344 292 344H288c-.3438 .0313-16.83 .9687-40.95 4.75c11.27 7 24.12 13.19 38.84 18.12C288 367.6 290.5 368 292.9 368z'
                                />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <UpdateCard
          updateCardHandler={updateCardHandler}
          open={showCardForm}
          onClose={() => setShowCardForm(false)}
        />
      </Elements>
      <Elements stripe={stripePromise}>
        <Upgrade open={showUpgrade} onClose={() => setShowUpgrade(false)} />
      </Elements>
    </>
  );
}
