import React, { useState, Fragment } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';
import { SearchCircleIcon } from '@heroicons/react/solid';
import MenuPopover from './menu-popover';
import { useAuth } from '../context/AuthContext';
import LoginModal from './login-modal';
import Banner from './banner';

export default function Header() {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const { isAuth, user, logout } = useAuth();
  const [expandSearch, setExpandSearch] = useState(false);
  const [q, setQ] = useState('');

  const search = () => {
    history.push({
      pathname: '/explore',
      search: `?query=${q}`,
    });
  };

  return (
    <>
      <div as='nav' className='bg-black'>
        <>
          <div className='px-2 sm:px-6 lg:px-8'>
            <div className='relative flex items-center justify-between h-16'>
              <div className='absolute inset-y-0 right-0 flex items-center sm:hidden'>
                {isAuth ? (
                  <button
                    onClick={() => setOpen(!open)}
                    type='button'
                    className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                  >
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </button>
                ) : (
                  <button
                    type='button'
                    className='mr-6 text-white font-bold'
                    onClick={() => setShowLogin(true)}
                  >
                    LOGIN
                  </button>
                )}
              </div>
              <div className='flex-1 flex sm:items-stretch sm:justify-start'>
                <div className='flex-shrink-0 flex items-center'>
                  <div className='text-2xl text-white font-bold'>
                    <Link to='/'>
                      <img
                        className='w-auto h-10'
                        src={`${process.env.PUBLIC_URL}/LearnRx_logo.png`}
                        alt='LearnRx logo'
                      />
                    </Link>
                  </div>

                  {isAuth && (
                    <div className='hidden ml-6 sm:flex gap-6'>
                      <Link
                        to='/explore'
                        className='uppercase oswald text-white font-medium'
                      >
                        explore
                      </Link>
                      <Link
                        to='/dashboard'
                        className='uppercase oswald text-white font-medium'
                      >
                        Dashboard
                      </Link>
                      <Link
                        to='/forum'
                        className='uppercase oswald text-white font-medium'
                      >
                        Forum
                      </Link>
                      <Link
                        to='/courses/coaching-certificate-program'
                        className='uppercase oswald text-white font-medium'
                      >
                        CCP
                      </Link>
                      {user && user.hasMembership && (
                        <Link
                          to='/programs'
                          className='uppercase oswald text-white font-medium'
                        >
                          Library
                        </Link>
                      )}
                      <Link
                        to='/opex-gyms'
                        className='uppercase oswald text-white font-medium'
                      >
                        GYMS
                      </Link>
                      {user && user.hasMethod && (
                        <Link
                          to='/courses/the-opex-method'
                          className='uppercase oswald text-white font-medium'
                        >
                          OPEX Method
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                <div className='hidden sm:block sm:ml-6'>
                  <div className='flex space-x-4' />
                </div>
              </div>
              <div className='absolute right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {isAuth ? (
                  <div className='flex gap-6 items-center bg-black'>
                    <div className='hidden md:flex gap-2'>
                      <button
                        onClick={() => setExpandSearch(!expandSearch)}
                        type='button'
                        className='text-gray-500 hover:text-gray-400'
                      >
                        <SearchCircleIcon className='w-8 h-8 ' />
                      </button>

                      <form onSubmit={search}>
                        <input
                          placeholder='Search...'
                          className={`transition-all duration-500 bg-gray-900 text-white focus:ring-blue-opex focus:border-blue-opex ${
                            expandSearch
                              ? 'text-sm w-48 px-2 py-2 border-gray-800'
                              : 'w-0 p-0 border-transparent'
                          }`}
                          onChange={(e) => setQ(e.target.value)}
                          type='text'
                          name='query'
                        />
                      </form>
                    </div>

                    <MenuPopover />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='hidden md:block ml-6 text-white font-bold'
                    onClick={() => setShowLogin(true)}
                  >
                    LOGIN
                  </button>
                )}
              </div>
            </div>
          </div>
          {open && (
            <div className='sm:hidden h-screen w-full absolute inset-0 bg-black bg-opacity-90 z-50'>
              <div className='px-2 pt-3 pb-3 space-y-4'>
                <div className='flex justify-between'>
                  <div>
                    <img
                      className='w-auto h-10'
                      src={`${process.env.PUBLIC_URL}/LearnRx_logo.png`}
                      alt='LearnRx logo'
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => {
                      setOpen(false);
                    }}
                    className='uppercase text-gray-100 hover:bg-gray-700 hover:text-white inline px-3 py-2 rounded-md text-base font-medium'
                  >
                    Close
                  </button>
                </div>
                <div className='oswald uppercase text-center space-y-4'>
                  <Link
                    to='/classes'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Education
                  </Link>
                  <Link
                    to='/dashboard'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Dashboard
                  </Link>
                  <Link
                    to='/forum'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Forum
                  </Link>
                  {user && user.hasMembership && (
                    <Link
                      to='/programs'
                      className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                    >
                      Library
                    </Link>
                  )}
                  <Link
                    to='/courses/coaching-certificate-program'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    CCP
                  </Link>
                  <Link
                    to='/opex-gyms'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    GYMS
                  </Link>
                  <div className='inline-block mt-4 pb-4 w-50 border-t' />
                  <Link
                    to='/account/edit'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Settings
                  </Link>
                  <a
                    href='https://intercom.help/coachrx/en/collections/2734275-opex-support'
                    target='_blank'
                    rel='noreferrer'
                    className='uppercase text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Help
                  </a>

                  <div className='flex justify-center md:hidden gap-2'>
                    <form
                      className='px-8 w-full gap-6 flex items-center'
                      onSubmit={search}
                    >
                      <input
                        placeholder='Search...'
                        className='flex-grow transition-all duration-500 bg-gray-800 text-white focus:ring-blue-opex focus:border-blue-opex text-sm w-48 px-2 py-2 border-gray-800'
                        onChange={(e) => setQ(e.target.value)}
                        type='text'
                        name='query'
                      />
                      <button
                        type='submit'
                        className='text-gray-500 hover:text-gray-400'
                      >
                        <SearchCircleIcon className='w-8 h-8 ' />
                      </button>
                    </form>
                  </div>

                  <button
                    type='button'
                    onClick={() => {
                      setOpen(false);
                      logout(history);
                    }}
                    className='text-center uppercase w-full text-left text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <LoginModal open={showLogin} onClose={() => setShowLogin(false)} />
      <Banner />
    </>
  );
}
