import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import axiosInstance from '../helpers/axios-instance';
import { useAuth } from '../context/AuthContext';

export default function SignupFree({ open, onClose, email }) {
  const [signupForm, setSignupForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const history = useHistory();
  const { setIsAuth, setUser } = useAuth();

  function addGATrackingConversion() {
    if (
      window.location.pathname ===
      '/classes/opex-principles-4-cs-of-metabolic-conditioning'
    ) {
      ReactGA.event('conversion', {
        action: 'conversion',
        category: 'conversion',
        send_to: 'AW-951662395/-8qeCIbojqADELvu5MUD',
      });
    } else if (
      window.location.pathname === '/classes/professional-coaching-blueprint'
    ) {
      ReactGA.event('conversion', {
        action: 'conversion',
        category: 'conversion',
        send_to: 'AW-951662395/-8qeCIbojqADELvu5MUD',
      });
    }
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const code = Cookies.get('referral_code');
    const classPage = Cookies.get('referral_page');

    const response = await axiosInstance.post('/registrations', {
      first_name: signupForm.first_name,
      last_name: signupForm.last_name,
      email: signupForm.email,
      password: signupForm.password,
      referral_code: code,
      referral_page: classPage,
    });

    if (response.data.error) {
      setError(response.data.error);
    } else {
      Cookies.remove('referral_code');
      Cookies.remove('referral_page');
      setIsAuth(true);
      setUser({
        ...response.data.user,
      });
      if (typeof window !== 'undefined') {
        if (window.fbq != null) {
          window.fbq('track', 'Subscribe');
          console.log('pixel event fired');
        }
      }

      addGATrackingConversion();

      history.push('/homepage');
    }
  };

  useEffect(() => {
    setSignupForm({
      ...signupForm,
      email,
    });
  }, [email]);

  const signupChange = (event) => {
    setSignupForm({
      ...signupForm,
      [event.target.name]: event.target.value,
    });
  };

  let buttonId = 'signup-free-button';
  if (
    window.location.pathname ===
    '/classes/opex-principles-4-cs-of-metabolic-conditioning'
  ) {
    buttonId = 'signup-free-button-4cs';
  } else if (
    window.location.pathname === '/classes/professional-coaching-blueprint'
  ) {
    buttonId = 'signup-free-button-blueprint';
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={onClose}
      >
        <div className='items-end justify-center min-h-screen text-center block p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block align-middle h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-md w-full sm:p-6'>
              <div>
                <div className='mt-3 text-center'>
                  <Dialog.Title
                    as='h3'
                    className='oswald uppercase text-3xl leading-6 font-medium text-gray-300'
                  >
                    Start a Free Class
                  </Dialog.Title>
                </div>
              </div>
              <div className='mt-5 sm:mt-6'>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium leading-5 text-gray-200'
                    >
                      First Name
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        onChange={signupChange}
                        value={signupForm.first_name}
                        name='first_name'
                        id='first_name'
                        type='text'
                        required
                        className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray-600 focus:border-blue-opex transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div className='mt-6'>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium leading-5 text-gray-200'
                    >
                      Last Name
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        onChange={signupChange}
                        value={signupForm.last_name}
                        name='last_name'
                        id='last_name'
                        type='text'
                        required
                        className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray-600 focus:border-blue-opex transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div className='mt-6'>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium leading-5 text-gray-200'
                    >
                      Email address
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        id='email'
                        onChange={signupChange}
                        value={signupForm.email}
                        name='email'
                        type='email'
                        required
                        className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-opex transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>

                  <div className='mt-6'>
                    <label
                      htmlFor='password'
                      className='block text-sm font-medium leading-5 text-gray-200'
                    >
                      Password
                    </label>
                    <div className='mt-1 rounded-md shadow-sm'>
                      <input
                        onChange={signupChange}
                        value={signupForm.password}
                        name='password'
                        id='password'
                        type='password'
                        required
                        className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                      />
                    </div>
                  </div>
                  {error && (
                    <div className='my-4'>
                      <p className='text-center text-red-500 font-bold'>
                        {error}
                      </p>
                    </div>
                  )}
                  <div className='mt-6'>
                    <button
                      id={buttonId}
                      className='uppercase block w-full bg-blue-opex border border-gray-800 rounded-md py-2 text-lg font-semibold text-white text-center hover:bg-blue-darker'
                      type='submit'
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SignupFree.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  email: PropTypes.string,
};
