import React from 'react';
import Navigation from './navigation';
import ClipboardCopy from '../clipboard-copy';
import { useAuth } from '../../context/AuthContext';

export default function Referrals() {
  const { user } = useAuth();
  return (
    <>
      <div className="bg-gray-600">
        <div className="max-w-7xl mx-auto p-4 md:p-8">
          <h1 className="uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight">
            Refer <span className="font-extrabold">Friends</span>
          </h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-8 md:flex">
        <Navigation />
        <section className="mt-4 md:mt-0 md:w-3/4">
          <div className="w-full bg-gray-800 p-8 flex flex-col justify-center items-center">
            <h2 className="oswald font-bold text-2xl uppercase">Refer another person, and you'll get $20 credit!</h2>
            <div className="mt-8 w-full">
              <p className="text-center mb-4">Copy and share this referral link:</p>
              <ClipboardCopy copyText={`https://learnrx.app?referralCode=${user?.referralCode}`} />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
