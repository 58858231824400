/* eslint-disable no-useless-escape */
export function validateEmailAddress(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );
}

export function validateUsername(username) {
  return /^[a-zA-Z0-9_]*$/.test(
    String(username).toLowerCase()
  );
}

// this will only work if the auth provider re-renders too
// && user.avatar_url === avatarUrl // (left empty for now as it comes back null)
export const areUserDetailsSame = ({ user, firstName, lastName, avatarUrl, country, username, emailAddress }) =>
  user.username === username &&
  user.first_name === firstName &&
  user.last_name === lastName &&
  user.email === emailAddress &&
  user.country === country;

export const isInvalidUserDetails = ({ firstName, lastName, emailAddress, username }) => {
  const errors = [];

  if (!firstName || firstName.length < 2) errors.push('Please enter your first name');

  if (!lastName || lastName.length < 2) errors.push('Please enter your last name');

  if (!emailAddress) errors.push('Please enter both your email address and password');

  if (!validateEmailAddress(emailAddress)) errors.push('Please enter a valid email address');

  if (!validateUsername(username)) errors.push('Please enter a valid username');

  if (errors.length > 0) return errors;
};
