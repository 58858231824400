import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { XCircleIcon } from '@heroicons/react/outline';
import axiosInstance from '../../helpers/axios-instance';

export default function Banner() {
  const [banner, setBanner] = useState({
    title: '',
    content: '',
    link: '',
  });
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    axiosInstance
      .get('/banners')
      .then((response) => {
        setBanner(response.data.banner);
        if (
          Cookies.get(
            response.data.banner.title.toLowerCase().replace(/ /g, '_')
          ) === 'hidden'
        ) {
          setShowBanner(false);
        } else {
          setShowBanner(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const hideBanner = () => {
    const { title } = banner;
    // snake case title
    const snakeTitle = title.toLowerCase().replace(/ /g, '_');
    Cookies.set(snakeTitle, 'hidden', { expires: 1 });
    setShowBanner(false);
  };

  if (!banner || !showBanner) {
    return null;
  }

  return (
    <div className='bg-blue-opex px-2 sm:px-6 lg:px-8 py-3 text-white font-medium uppercase flex items-center justify-between relative'>
      <div className='sm:flex sm:items-center flex flex-col sm:flex-row sm:justify-center  w-full'>
        <div className='flex items-center flex-col sm:flex-row'>
          <div>
            <p className='font-bold text-center sm:text-left'>{banner.title}</p>
            <p className='text-sm text-center sm:text-left'>{banner.content}</p>
          </div>
          {banner.link && !banner.external_link && (
            <Link
              to={banner.link}
              className='sm:animate-wiggle sm:ml-5 sm:mt-0 mt-3 rounded-md bg-white text-blue-opex p-2 text-sm font-bold uppercase sm:w-auto w-full text-center'
            >
              {banner.link_text}
            </Link>
          )}

          {banner.link && banner.external_link && (
            <a
              target='_blank'
              rel='noreferrer'
              href={banner.link}
              className='sm:animate-wiggle sm:ml-5 sm:mt-0 mt-3 rounded-md bg-white text-blue-opex p-2 text-sm font-bold uppercase sm:w-auto w-full text-center'
            >
              {banner.link_text}
            </a>
          )}
        </div>
      </div>
      <div className='absolute top-5 right-5'>
        <button onClick={hideBanner} type='button'>
          <XCircleIcon className='w-5 h-5' />
        </button>
      </div>
    </div>
  );
}
