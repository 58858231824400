import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react';

export default function PauseSubscription({ open, onClose, pauseHandler }) {
  const [length, setLength] = useState(1);

  function lengthChange(event) {
    setLength(event.target.value);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={onClose}
      >
        <div className='items-end justify-center min-h-screen text-center block p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block align-middle h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-md w-full sm:p-6'>
              <div>
                <div className='mt-3 text-center'>
                  <Dialog.Title
                    as='h3'
                    className='oswald uppercase text-3xl leading-6 font-medium text-gray-200'
                  >
                    Are you sure?
                  </Dialog.Title>
                </div>
              </div>
              <div className='mt-8 sm:mt-8'>
                <h2 className='oswald uppercase text-xl leading-6 font-medium text-gray-400 mb-2'>
                  You will miss out on all these feature:
                </h2>
                <div className='flex gap-6'>
                  <div>
                    <div className='flex items-center py-2'>
                      <CheckCircleIcon className='mr-3 w-4 h-4 text-blue-opex' />
                      All premium classes
                    </div>
                    <div className='flex items-center py-2'>
                      <CheckCircleIcon className='mr-3 w-4 h-4 text-blue-opex' />
                      PDF workbooks
                    </div>
                    <div className='flex items-center py-2'>
                      <CheckCircleIcon className='mr-3 w-4 h-4 text-blue-opex' />
                      New classes added monthly
                    </div>
                    <div className='flex items-center py-2'>
                      <CheckCircleIcon className='mr-3 w-4 h-4 text-blue-opex' />
                      Community Forums
                    </div>
                  </div>
                </div>
                <div className='mt-4'>
                  <select
                    className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-gray-600 focus:border-blue-opex transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    value={length}
                    onBlur={lengthChange}
                    onChange={lengthChange}
                  >
                    <option value={1}>1 Month</option>
                    <option value={2}>2 Months</option>
                    <option value={3}>3 Months</option>
                  </select>
                </div>
                <div className='flex mt-4'>
                  <button
                    type='button'
                    onClick={() => pauseHandler(length)}
                    className='mt-3 uppercase block w-full bg-blue-opex border border-blue-opex rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-darker mr-6'
                  >
                    YES
                  </button>
                  <button
                    type='button'
                    onClick={onClose}
                    className='mt-3 uppercase block w-full bg-gray-500 border border-gray-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-700'
                  >
                    I've changed my mind
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

PauseSubscription.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pauseHandler: PropTypes.func,
};
