import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Navigation from './navigation';
import axiosInstance from '../../helpers/axios-instance';
import SignatureInput from '../signature-input';

export default function Contracts() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [signature, setSignature] = useState(null);
  const [contract, setContract] = useState({
    contract_text: '',
    signature_image_1: '',
    signature_image_2: '',
  });
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  useEffect(() => {
    // get the contract for the current user.
    setLoading(true);
    axiosInstance
      .get(`/contracts/${id}`)
      .then((response) => {
        setContract(response.data.contract);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        history.push('/account/contracts');
      });
  }, []);

  const saveSignature = (signature) => {
    setSignature(signature);
    setOpen(false);
  };

  const sigPag = useRef(null);

  const handleSubmit = () => {
    setProcessing(true);
    axiosInstance
      .post(`/contracts/${id}/signed`, {
        contract: { ...contract, signature_image_1: signature },
      })
      .then((response) => {
        console.log(response);
        setProcessing(false);
        history.push('/account/contracts');
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  return (
    <>
      <div className='bg-gray-600'>
        <div className='max-w-7xl mx-auto p-4 md:p-8'>
          <h1 className='uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight'>
            My <span className='font-extrabold'>Contract</span>
          </h1>
        </div>
      </div>
      <div className='max-w-7xl mx-auto py-8 md:flex'>
        <Navigation />
        <section className='mt-4 md:mt-0 md:w-3/4'>
          <div
            id='contract'
            className='bg-white text-gray-800 px-8 lg:max-w-6xl mx-auto mt-8 mb-8 overflow-hidden'
          >
            <div className='my-8'>
              <img
                alt='OPEX Logo'
                width='250'
                src='https://ucarecdn.com/b5c376d0-525d-4cfe-8d8b-0dcde7349ab6/OPEXFitnessRGB.png'
              />
            </div>
            <div
              className='text-black'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contract.contract_text, {}),
              }}
            />
            {!signature && (
              <div className='mt-8'>
                <button
                  className='bg-blue-200 text-blue-800 p-2 w-full max-w-xl'
                  type='button'
                  onClick={() => setOpen(true)}
                >
                  Sign here
                </button>
              </div>
            )}
            <div className='mt-8'>
              {signature && !open && (
                <img
                  src={signature}
                  alt='signature'
                  style={{ display: 'block', width: '250px' }}
                />
              )}
            </div>

            <div className='mt-8 mb-8'>
              Optimum Performance Training, Inc (DBA OPEX)
              <br />
              7645 E Evans Rd Suite 140
              <br />
              Scottsdale, AZ 85260
              <br />
              480-699-3045
              <br />
            </div>
            {signature && (
              <div className='mt-8 mb-8'>
                <button
                  className='bg-blue-opex flex items-center text-lg font-bold text-white p-4 w-full uppercase oswald rounded-md hover:bg-blue-darker'
                  type='button'
                  onClick={handleSubmit}
                >
                  {processing && (
                    <svg
                      className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      />
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      />
                    </svg>
                  )}
                  {processing ? 'Saving Contract' : 'Complete Signing Process'}
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
      {open && (
        <SignatureInput
          saveSignature={saveSignature}
          closeHandler={() => setOpen(false)}
          open={open}
        />
      )}
    </>
  );
}
