/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { useAuth } from '../context/AuthContext';
import { classNames } from '../utils/common';

export default function MenuPopover() {
  const { logout, user } = useAuth();
  const history = useHistory();

  let avatarImage = (
    <span className='text-sm font-medium leading-none text-white' />
  );
  if (user && user.avatarUrl) {
    avatarImage = (
      <img className='rounded-full' src={user.avatarUrl} alt={user.name} />
    );
  } else if (user && user.initials) {
    avatarImage = (
      <span className='text-sm font-medium leading-none text-white'>
        {user.initials}
      </span>
    );
  }

  return (
    <Popover className='hidden sm:block relative'>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(open ? 'text-gray-400' : 'text-gray-400', '')}
          >
            <div className='border border-gray-700 flex p-2 rounded-md'>
              <img
                className='h-6 mr-2'
                src={`${process.env.PUBLIC_URL}/OPEX Logo.png`}
                alt='OPEX Logo'
              />
              {user ? (
                <span className='inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500'>
                  {avatarImage}
                </span>
              ) : null}
            </div>
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              static
              className='absolute right-0 z-10 transform mt-3 px-2 w-screen max-w-xs sm:px-0'
            >
              <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                <div className='relative bg-gray-800 text-white'>
                  <Link
                    className='block py-3 px-6 hover:bg-gray-700 w-full oswald'
                    to='/account/edit'
                  >
                    Settings
                  </Link>
                  <a
                    rel='noreferrer'
                    target='_blank'
                    className='block py-3 px-6 hover:bg-gray-700 w-full oswald'
                    href='https://intercom.help/coachrx/en/collections/2734275-opex-support'
                  >
                    Help
                  </a>
                  <button
                    onClick={() => logout(history)}
                    className='text-left block py-3 px-6 hover:bg-gray-700 w-full'
                    type='button'
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
