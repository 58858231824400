/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect, useRef } from 'react';
import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/solid';
import { Widget } from '@uploadcare/react-widget';
import axiosInstance from '../../helpers/axios-instance';
import { useAuth } from '../../context/AuthContext';
import {
  isInvalidUserDetails,
  areUserDetailsSame,
} from '../../utils/is-invalid-user-details';
import Navigation from './navigation';

export default function EditAccount() {
  const { user } = useAuth();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [country, setCountry] = useState('');
  const [username, setUsername] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const isInvalid = isInvalidUserDetails({
    firstName,
    lastName,
    emailAddress,
    username,
  });
  const avatarWidget = useRef();
  const [avatarValue, setAvatarValue] = useState();
  const [usernameInvalid, setUsernameInvalid] = useState(false);

  const patchUserProfile = async () => {
    try {
      await axiosInstance({
        method: 'patch',
        url: `/profile`,
        data: {
          user: {
            first_name: firstName,
            last_name: lastName,
            avatar_url: avatarUrl,
            country,
            username,
            email: emailAddress,
          },
        },
      });
      setSuccess(true);
      setError(null);
    } catch (error) {
      setSuccess(null);
      setError(error.response.data.error);
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    }
  }, [success]);

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      try {
        const { data: { user } = {} } = await axiosInstance.get(`/profile`);
        if (!didCancel) {
          setFirstName(user.firstName);
          setLastName(user.lastName);
          setAvatarUrl(user.avatarUrl);
          setCountry(user.country);
          setUsername(user.username);
          setEmailAddress(user.email);
        }
      } catch (error) {}
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, []);

  const changeAvatar = (fileInfo) => {
    console.log('test');
    setAvatarUrl(fileInfo.cdnUrl);
    setAvatarValue(null);
  };

  const handleUpdateAccount = async (event) => {
    event.preventDefault();
    if (isInvalid) return false;
    await patchUserProfile();
  };

  const validateUsername = (value) => {
    const pattern = /^[a-zA-Z0-9_]*$/;
    const result = pattern.test(value);
    if (result === true) {
      console.log('passed');
      setUsernameInvalid(false);
      setUsername(value);
    } else {
      setUsernameInvalid(true);
      setUsername(value);
    }
  };

  return (
    <>
      <div className='bg-gray-600'>
        <div className='max-w-7xl mx-auto p-4 md:p-8'>
          <h1 className='uppercase oswald text-3xl font-light text-white sm:text-5xl sm:tracking-tight'>
            Edit <span className='font-extrabold'>Profile</span>
          </h1>
        </div>
      </div>
      <div className='max-w-7xl mx-auto'>
        {success && (
          <div className='mt-6 rounded-md bg-green-50 p-4 mb-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <CheckCircleIcon
                  className='h-5 w-5 text-green-400'
                  aria-hidden='true'
                />
              </div>
              <div className='ml-3'>
                <p className='text-sm font-medium text-green-800'>
                  Successfully updated
                </p>
              </div>
              <div className='ml-auto pl-3'>
                <div className='-mx-1.5 -my-1.5'>
                  <button
                    type='button'
                    className='inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600'
                    onClick={() => setSuccess(null)}
                  >
                    <span className='sr-only'>Dismiss</span>
                    <XIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className='mt-6 rounded-md bg-red-50 p-4 mb-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <XCircleIcon
                  className='h-5 w-5 text-red-400'
                  aria-hidden='true'
                />
              </div>
              <div className='ml-3'>
                <h3 className='text-sm font-medium text-red-800'>{error}</h3>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='max-w-7xl mx-auto py-8 md:flex'>
        <Navigation />
        <div className='mt-4 md:mt-0 md:w-3/4'>
          <form
            className='bg-gray-800 shadow sm:rounded-lg p-6'
            onSubmit={handleUpdateAccount}
            method='POST'
          >
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='email-address'
                  className='block text-sm font-medium text-gray-200'
                >
                  Email address
                  <input
                    type='text'
                    name='email-address'
                    id='email-address'
                    className='mt-1 text-gray-700  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    value={emailAddress}
                    onChange={({ target }) => setEmailAddress(target.value)}
                  />
                </label>
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='email-address'
                  className='block text-sm font-medium text-gray-200'
                >
                  Username (letters, numbers and underscores only. No spaces.)
                  <input
                    type='text'
                    name='username'
                    id='username'
                    className={`mt-1 text-gray-700  block w-full shadow-sm sm:text-sm ${
                      usernameInvalid
                        ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                        : 'border-gray-300 focus:ring-gray-300 focus:border-gray-300'
                    } rounded-md`}
                    value={username}
                    onChange={({ target }) => validateUsername(target.value)}
                  />
                  {usernameInvalid && (
                    <span className='mt-1 text-sm text-red-500'>
                      Invalid format.
                    </span>
                  )}
                </label>
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='first-name'
                  className='block text-sm font-medium text-gray-200'
                >
                  First name
                  <input
                    type='text'
                    name='first-name'
                    id='first-name'
                    className='mt-1 text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    value={firstName}
                    onChange={({ target }) => setFirstName(target.value)}
                  />
                </label>
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium text-gray-200'
                >
                  Last name
                  <input
                    type='text'
                    name='last-name'
                    id='last-name'
                    className='mt-1 text-gray-700  focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                    value={lastName}
                    onChange={({ target }) => setLastName(target.value)}
                  />
                </label>
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <label
                  htmlFor='country'
                  className='block text-sm font-medium text-gray-200'
                >
                  Country / Region
                  <select
                    id='country'
                    name='country'
                    className='mt-1 text-gray-700  block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    value={country}
                    onChange={({ target }) => setCountry(target.value)}
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </label>
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <p className='block text-sm font-medium text-white'>Photo</p>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <div className='flex items-center'>
                    {avatarUrl ? (
                      <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
                        <img
                          alt={user.firstName}
                          src={avatarUrl}
                          className='h-full w-full text-gray-300'
                        />
                      </span>
                    ) : (
                      <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
                        <svg
                          className='h-full w-full text-gray-300'
                          fill='currentColor'
                          viewBox='0 0 24 24'
                        >
                          <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                        </svg>
                      </span>
                    )}
                    <button
                      onClick={() => avatarWidget.current.openDialog()}
                      type='button'
                      className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <button
                type='submit'
                className={`${
                  isInvalid && `cursor-default opacity-50 `
                } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-opex hover:bg-blue-opex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-opex`}
                disabled={isInvalid}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      <Widget
        value={avatarValue}
        multiple={false}
        onChange={changeAvatar}
        ref={avatarWidget}
        publicKey='d15def205fb38eb5d3a7'
        id='file'
        clearable
        previewStep
        imageShrink='800x600'
        crop='1:1'
        imagesOnly
        tabs='file camera url gdrive gphotos dropbox instagram'
      />
    </>
  );
}
