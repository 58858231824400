import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './styles/index.css';
import { IntercomProvider } from 'react-use-intercom';
import TagManager from 'react-gtm-module';
import App from './App';
import { AuthProvider } from './context/AuthContext';

const INTERCOM_APP_ID = 'u4mn5o1d';

const tagManagerArgs = {
  gtmId: 'GTM-WVV9LVC'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <IntercomProvider appId={INTERCOM_APP_ID}>
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </IntercomProvider>,
  document.getElementById('root')
);
