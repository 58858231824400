export const HOME = '/';
export const DASHBOARD = '/homepage';
export const MY_PROGRESS = '/dashboard';
export const PLANS = '/plans';
export const COURSES_PAGE = '/courses/:slug';
export const PLAYLIST_PAGE = '/playlists/:slug';
export const RESOURCES_PAGE = '/courses/:slug/resources';
export const PRINCIPLES = '/opex-principles/';
export const ESPANOL = '/opex-espanol/';
export const OPEX_GYMS = '/opex-gyms';
export const OPEX_GYMS_RESOURCES = '/opex-gyms-resources';
export const CLASSES = '/classes/';
export const CLASSES_PAGE = '/classes/:slug';
export const CHAPTER_PAGE = '/classes/:slug/chapters/:chapterSlug';
export const LIBRARY_SEARCH = 'library-search';
export const ARTICLES = '/articles';
export const ARTICLE = '/articles/:slug';
export const INSTRUCTORS = '/instructors';
export const INSTRUCTOR = '/instructors/:slug';
export const FORUM = '/forum';
export const FORUM_TOPIC = '/forum/topic/:topic';
export const FORUM_CATEGORY = '/forum/topic/:topic/category/:category';
export const FORUM_POST = '/forum/topic/:topic/post/:post';
export const ADMIN = '/admin';
export const ACCOUNT = '/account';
export const SUPPORT = '/support';
export const PAYMENTS = '/account/billing';
export const CHANGE_PASSWORD = '/account/change-password';
export const PRIVACY = '/privacy';
export const CCP_CONSULT = '/ccp-educational-consult';
export const CCP_SALES = '/ccp-sales';
export const CCP_PRESALE = '/ccp-presale';
export const WORKSHOP = '/workshop';
export const SPANISH_WORKSHOP = '/spanish-workshop';
export const PROGRAMS = '/programs';
export const PROGRAM = '/programs/:program';
export const EXERCISES = '/exercises';
export const EXERCISE = '/exercises/:exercise';
export const CASE_STUDIES = '/case-studies';
export const CASE_STUDY = '/case-studies/:caseStudy';
export const NOT_FOUND = '/not-found';
export const FINAL_PROJECT = '/coaching-certificate-program/final-project';
export const FINAL_PROJECT_NEW =
  '/coaching-certificate-program/final-project-new';
export const SEARCH = '/search';
export const KEEP_LEARNING = '/ccp/keep-learning';
export const EXPLORE = '/explore';
