/* eslint-disable no-multi-assign */
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import ReactLoader from './components/loader';
import * as ROUTES from './constants/routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProtectedRoute from './helpers/protected-route';
import LoggedInRoute from './helpers/logged-in-route';
import { useAuth } from './context/AuthContext';
import Account from './pages/account';

const Homepage = lazy(() => import('./pages/homepage'));
const Dashboard = lazy(() => import('./pages/dashboard'));
const NotFound = lazy(() => import('./pages/not-found'));
const Admin = lazy(() => import('./pages/admin'));
const Classes = lazy(() => import('./pages/classes'));
const Courses = lazy(() => import('./pages/courses'));
const Playlists = lazy(() => import('./pages/playlists'));
const Resources = lazy(() => import('./pages/resources'));
const Forum = lazy(() => import('./pages/forum/index'));
const Instructors = lazy(() => import('./pages/instructors'));
const Instructor = lazy(() => import('./pages/instructor'));
const Chapter = lazy(() => import('./pages/chapter'));
const MyProgress = lazy(() => import('./pages/my-progress'));
const Plans = lazy(() => import('./pages/plans'));
const Search = lazy(() => import('./pages/search'));
const ClassesList = lazy(() => import('./pages/class-list'));
const OpexPrinciples = lazy(() => import('./pages/opex-principles'));
const OpexGyms = lazy(() => import('./pages/opex-gyms'));
const OpexGymsResources = lazy(() => import('./pages/opex-gyms-resources'));
const CcpConsult = lazy(() => import('./pages/ccp-consult'));
const CcpSales = lazy(() => import('./pages/ccp-sales/index'));
const CcpPresale = lazy(() => import('./pages/ccp-presale/index'));
const Contract = lazy(() => import('./pages/ccp-sales/contract'));
const CohortCalls = lazy(() => import('./pages/ccp-sales/cohort-calls'));
const AdvocateCalls = lazy(() => import('./pages/ccp-sales/advocate-calls'));
const BookShipping = lazy(() => import('./pages/ccp-sales/book-shipping'));
const Programs = lazy(() => import('./pages/programs'));
const Espanol = lazy(() => import('./pages/spanish'));
const FinalProject = lazy(() => import('./pages/final-project'));
const FinalProjectNew = lazy(() => import('./pages/final-project-new'));
const Workshop = lazy(() => import('./pages/workshop'));
const SpanishWorkshop = lazy(() => import('./pages/spanish-workshop'));
const Exercises = lazy(() => import('./pages/exercises'));
const KeepLearning = lazy(() => import('./pages/keep-learning'));
const CaseStudies = lazy(() => import('./pages/case-studies'));

ReactGA.initialize('G-TRVXYN8GX8');
export default function App() {
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    document.title = 'LearnRx - OPEX Fitness';
    const referralCode = new URLSearchParams(window.location.search).get(
      'referralCode'
    );
    if (referralCode) {
      Cookies.set('referral_code', referralCode, { expires: 30 });
    }
  }, []);

  return (
    <Suspense fallback={<ReactLoader />}>
      <Switch>
        <LoggedInRoute
          exact
          user={user}
          redirect={ROUTES.DASHBOARD}
          path={ROUTES.HOME}
        >
          <Homepage />
        </LoggedInRoute>
        <Route exact path={ROUTES.PLANS} component={Plans} />
        <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
        <ProtectedRoute user={user} path={ROUTES.ACCOUNT}>
          <Account />
        </ProtectedRoute>
        <ProtectedRoute user={user} path={ROUTES.MY_PROGRESS} exact>
          <MyProgress />
        </ProtectedRoute>
        <Route
          path={[ROUTES.PROGRAMS, ROUTES.PROGRAM]}
          exact
        >
          <Programs />
        </Route>
        <Route
          path={[ROUTES.EXERCISES, ROUTES.EXERCISE]}
          exact
        >
          <Exercises />
        </Route>
        <Route
          path={[ROUTES.CASE_STUDIES, ROUTES.CASE_STUDY]}
          exact
        >
          <CaseStudies />
        </Route>
        <Route
          exact
          path={[
            ROUTES.FORUM,
            ROUTES.FORUM_TOPIC,
            ROUTES.FORUM_POST,
            ROUTES.FORUM_CATEGORY,
          ]}
          component={Forum}
        />
        <Route exact path={ROUTES.COURSES_PAGE} component={Courses} />
        <Route exact path={ROUTES.PLAYLIST_PAGE} component={Playlists} />
        <Route exact path={ROUTES.RESOURCES_PAGE} component={Resources} />
        <Route exact path={ROUTES.PRINCIPLES} component={OpexPrinciples} />
        <Route exact path={ROUTES.ESPANOL} component={Espanol} />
        <Route exact path={ROUTES.OPEX_GYMS} component={OpexGyms} />
        <Route
          exact
          path={ROUTES.OPEX_GYMS_RESOURCES}
          component={OpexGymsResources}
        />
        <Route exact path={ROUTES.CLASSES} component={ClassesList} />
        <Route exact path={ROUTES.CLASSES_PAGE} component={Classes} />
        <Route exact path={ROUTES.CHAPTER_PAGE} component={Chapter} />
        <Route exact path={ROUTES.INSTRUCTORS} component={Instructors} />
        <Route exact path={ROUTES.INSTRUCTOR} component={Instructor} />
        <Route exact path={ROUTES.CCP_CONSULT} component={CcpConsult} />
        <Route exact path={ROUTES.CCP_SALES} component={CcpSales} />
        <Route exact path={ROUTES.CCP_PRESALE} component={CcpPresale} />
        <Route exact path='/contract' component={Contract} />
        <Route exact path='/ccp-sales/step-3' component={CohortCalls} />
        <Route exact path='/ccp-sales/step-4' component={AdvocateCalls} />
        <Route exact path='/ccp-sales/step-5' component={BookShipping} />
        <Route exact path={ROUTES.KEEP_LEARNING} component={KeepLearning} />
        <ProtectedRoute user={user} path={ROUTES.FINAL_PROJECT}>
          <FinalProject />
        </ProtectedRoute>
        <ProtectedRoute user={user} path={ROUTES.FINAL_PROJECT_NEW}>
          <FinalProjectNew />
        </ProtectedRoute>
        <Route exact path={ROUTES.WORKSHOP} component={Workshop} />
        <Route
          exact
          path={ROUTES.SPANISH_WORKSHOP}
          component={SpanishWorkshop}
        />
        <Route path={ROUTES.ADMIN} component={Admin} />
        <Route exact path={ROUTES.EXPLORE} component={Search} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}
