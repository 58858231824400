import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchCircleIcon  } from '@heroicons/react/solid'

export default function FooterCta() {
  const [q, setQ] = useState('');
  const history = useHistory();
  const search = () => {
    history.push({
      pathname: '/explore',
      search: `?query=${q}`,
    });
  }
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="flex flex-col md:flex-row gap-12 uppercase text-xl">
        <a
          className="items-center py-4 justify-center flex px-8 oswald uppercase border font-bold rounded-md border-blue-opex"
          href="/forum"
        >
          Join the discussion
        </a>
        <div className="flex-grow">
          <div className="w-full">
            <form onSubmit={search}>
              <div className="mt-1 flex gap-4 rounded-md shadow-sm">
                  <input
                    onChange={(e) => setQ(e.target.value)}
                    type="text"
                    name="q"
                    id="email"
                    className="placeholder-gray-300 focus:ring-blue-opex focus:border-blue-opex flex-grow text-white bg-gray-500 w-full md:w-1/2 block w-full py-4 rounded-md pl-10 sm:text-sm border-gray-700"
                    placeholder="Search for content here..."
                  />
                <button
                  type="submit"
                  className="px-8 rounded-md uppercase bg-blue-opex relative inline-flex items-center"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
