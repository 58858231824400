import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Dialog, Transition } from '@headlessui/react';
import Cookies from 'js-cookie';
import axiosInstance from '../../helpers/axios-instance';
import { useAuth } from '../../context/AuthContext';
import { pushDataToGTM } from '../../utils/googleTagManager';

export default function Upgrade({ open, onClose }) {
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const [promo, setPromo] = useState();
  const [promoError, setPromoError] = useState('');
  const [showPromo, setShowPromo] = useState(false);
  const elements = useElements();
  const [price, setPrice] = useState({
    name: 'yearly',
    price_id: process.env.REACT_APP_LEARNRX_YEARLY,
  });
  const [prices, setPrices] = useState({
    yearly: 399,
    monthly: 40,
  });
  const { setUser } = useAuth();

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#fefefe',
        color: '#fff',
        fontWeight: 500,
        fontSize: '16px',

        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#6B7280',
        },
      },
      invalid: {
        iconColor: '#F87171',
        color: '#F87171',
      },
    },
  };

  const applyDiscount = () => {
    // call api here and bring back new prices.
    axiosInstance
      .post('/subscriptions/coupons', {
        promo,
      })
      .then((resp) => {
        if (resp.data.error) {
          setPromoError(resp.data.error);
          setPrices(resp.data.prices);
          setPromo('');
        } else {
          setPromoError('');
          setPrices(resp.data.prices);
        }
      })
      .catch((resp) => {
        setPromoError('An error occured. Please try again.');
      });
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const code = Cookies.get('referral_code');
    const classPage = Cookies.get('referral_page');
    // Use your card Element with other Stripe.js APIs
    const {
      data: { clientSecret, user, error },
    } = await axiosInstance.patch('/subscriptions/upgrade', {
      price_id: price.price_id,
      referral_code: code,
      referral_page: classPage,
      promo,
    });

    if (error) {
      console.log('[error]', error);
      setProcessing(false);
      alert(error);
    } else {
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: user.name,
              email: user.email,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            setProcessing(false);
            alert(result.error.message);
          } else {
            setUser({
              ...user,
              hasMembership: true,
            });
            pushDataToGTM( {
              transactionId: result.paymentIntent.id, 
              transactionAmount: result.paymentIntent.amount / 100.0,
            } );

            setTimeout(() => {
              onClose();
              setProcessing(false);
              window.location.reload(false);
            }, 2000);
          }
        });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-0 overflow-y-auto'
        open={open}
        onClose={onClose}
      >
        <div className='items-end justify-center min-h-screen text-center block p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block align-middle h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-md w-full sm:p-6'>
              <div>
                <div className='mt-3 text-center'>
                  <Dialog.Title
                    as='h3'
                    className='oswald uppercase text-3xl leading-6 font-medium text-gray-300'
                  >
                    Upgrade
                  </Dialog.Title>
                </div>
              </div>

              <div className='flex justify-center'>
                <div className='w-auto mx-auto  mt-4 py-2 px-6 text-center rounded-md focus:ring-blue-opex relative'>
                  <span className='text-3xl font-extrabold text-gray-300'>
                    ${prices.yearly} /
                    <span className='lowercase text-sm uppercase '>YEAR</span>
                  </span>
                </div>
              </div>
              <div className='mt-5 sm:mt-6'>
                <form onSubmit={handleSubmit}>
                  <div className='mt-6'>
                    <div className='bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'>
                      <CardElement options={CARD_OPTIONS} />
                    </div>
                  </div>
                  <button
                    onClick={() => setShowPromo(!showPromo)}
                    type='button'
                    className='mt-3 w-full text-center cursor-pointer text-center text-sm underline'
                  >
                    Have a promo code?
                  </button>
                  {showPromo && (
                    <div>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                          <input
                            onChange={(e) => setPromo(e.target.value)}
                            value={promo}
                            name='code'
                            placeholder='PROMO123'
                            id='promo'
                            className='bg-gray-800 form-input group-btn block w-full rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5'
                          />
                        </div>
                        <button
                          onClick={applyDiscount}
                          type='button'
                          className='-ml-px relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm leading-5 font-medium rounded-r-md text-gray-300 bg-gray-600 hover:text-gray-200 hover:bg-white focus:outline-none focus:shadow-outline-blue-opex focus:border-blue-opex active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'
                        >
                          <span>Apply</span>
                        </button>
                      </div>
                    </div>
                  )}
                  {promoError && (
                    <p className='text-red-500 mt-1 text-sm'>{promoError}</p>
                  )}
                  <div className='mt-6'>
                    <button
                      className='flex justify-center items-center uppercase block w-full bg-blue-opex border border-gray-800 rounded-md py-2 text-lg font-semibold text-white text-center hover:bg-blue-darker'
                      type='submit'
                      disabled={!stripe}
                    >
                      {processing && (
                        <svg
                          className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          />
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          />
                        </svg>
                      )}
                      {processing ? 'Processing' : 'Sign up'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Upgrade.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
