import React, { useState, Fragment } from 'react'; 
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PropTypes } from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import axiosInstance from '../../helpers/axios-instance';

export default function UpdateCard({ updateCardHandler, onClose, open }) {
  const [processing, setProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#fefefe',
        color: '#fff',
        fontWeight: 500,
        fontSize: '16px',
        
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883'
        },
        '::placeholder': {
          color: '#6B7280'
        }
      },
      invalid: {
        iconColor: '#F87171',
        color: '#F87171'
      }
    }
  };

  const changeCard = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const response = await axiosInstance.post('/setup_intent');
    stripe
      .confirmCardSetup(response.data.clientSecret, {
        payment_method: {
          card: cardElement
        }
      })
      .then((result) => {
        if (result.error) {
          setCheckoutError(result.error.message);
          setProcessing(false);
        } else {
          updateCardHandler(result.setupIntent);
        }
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={onClose}>
        <div className="items-end justify-center min-h-screen text-center block p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-md w-full sm:p-6">
              <div>
                <div className="mt-3 text-center">
                  <Dialog.Title as="h3" className="oswald uppercase text-3xl leading-6 font-medium text-gray-300">
                    Update Card
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <form onSubmit={changeCard}>
                  <div className="mt-6">
                    <div className="bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-600 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue-500 focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                      <CardElement options={CARD_OPTIONS} />
                    </div>
                  </div>
                  <div className="mt-6">
                    <button
                      className="uppercase block w-full bg-blue-opex border border-gray-800 rounded-md py-2 text-lg font-semibold text-white text-center hover:bg-blue-darker"
                      type="submit"
                      disabled={!stripe}
                    >
                      Update Card
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

UpdateCard.propTypes = {
  updateCardHandler: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
