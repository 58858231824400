import React from 'react';
import ExitIntent from './exit-intent';

const navigation = {
  about: [
    { name: 'About OPEX', href: 'https://www.opexfit.com/story-of-opex/' },
    { name: 'Meet the team', href: 'https://www.opexfit.com/meet-the-team' },
    { name: 'OPEX Remote Coaching', href: 'https://www.opexrc.com' },
    { name: 'Ambassador Program', href: 'https://www.opexfit.com/ambassador-program'},
    { name: 'Find a gym', href: 'https://www.opexgyms.com/find-a-gym' },
    { name: 'Careers', href: 'https://www.opexfit.com/careers' },
    { name: 'Contact', href: 'https://www.opexfit.com/contact-opex' }
  ],
  courses: [
    { name: 'CCP', href: '/courses/coaching-certificate-program' },
    { name: 'Program Design Essentials Playlist', href: '/playlists/program-design-essentials-playlist' },
    { name: 'Remote Coaching Playlist', href: '/playlists/remote-coaching-playlist' },
    { name: 'Assessing Strength & Energy Systems Playlist', href: '/playlists/fitness-monitoring-assessing-strength-energy-systems-playlist' },
    { name: 'Fitness Coaching Blueprint', href: '/classes/professional-coaching-blueprint' }
  ],
  additional: [
    { name: 'Plans', href: 'https://learnrx.app/plans' },
    { name: 'CoachRx', href: 'https://coachrx.app/' },
    { name: 'Blog', href: 'https://www.opexfit.com/blog' },
    { name: 'Free Resources', href: 'https://www.opexfit.com/free-downloads/?hsLang=en' },
    { name: 'Podcast', href: 'https://www.opexfit.com/podcast/' },
    { name: 'OPEX Apparel Store', href: 'https://www.teepublic.com/stores/opex-fitness?ref_id=27292&utm_campaign=27292&utm_medium=affiliate&utm_source=OPEX%2BFitness' }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://facebook.com/OPEXFitness',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      )
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/opexfitness',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      )
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/OPEXFitness',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCCgDGih2kSp0A6W_0cVYuaQ',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="-21 -117 682.66672 682">
          <path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" />
        </svg>
      )
    },
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@opexfitness',
      icon: (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 512 512">
          <path d="m256 0c-141.363 0-256 114.637-256 256s114.637 256 256 256 256-114.637 256-256-114.637-256-256-256zm128.43 195.873v34.663c-16.345.006-32.226-3.197-47.204-9.516-9.631-4.066-18.604-9.305-26.811-15.636l.246 106.693c-.103 24.025-9.608 46.598-26.811 63.601-14 13.84-31.74 22.641-50.968 25.49-4.518.669-9.116 1.012-13.766 1.012-20.583 0-40.124-6.668-56.109-18.97-3.008-2.316-5.885-4.827-8.624-7.532-18.644-18.427-28.258-43.401-26.639-69.674 1.235-19.999 9.242-39.072 22.59-54.021 17.66-19.782 42.366-30.762 68.782-30.762 4.65 0 9.248.349 13.766 1.018v12.816 35.652c-4.284-1.413-8.859-2.19-13.623-2.19-24.134 0-43.659 19.69-43.298 43.842.229 15.453 8.67 28.961 21.12 36.407 5.851 3.5 12.582 5.668 19.765 6.062 5.628.309 11.032-.475 16.036-2.127 17.243-5.696 29.682-21.892 29.682-40.994l.057-71.447v-130.44h47.736c.046 4.73.526 9.345 1.418 13.817 3.603 18.101 13.806 33.805 28.006 44.511 12.382 9.339 27.8 14.875 44.511 14.875.011 0 .149 0 .137-.011v12.861z" />
        </svg>
      )
    }
  ]
};

export default function Footer() {
  return (
    <>
      <footer className="bg-black" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="md:grid md:grid-cols-3 md:gap-8">
            <div className="col-span-1">
              <h3 className="text-lg oswald font-semibold text-gray-400 tracking-wider uppercase">About</h3>
              <ul className="mt-4 space-y-4 uppercase">
                {navigation.about.map((item) => (
                  <li key={item.name}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.href}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-1">
              <h3 className="text-lg oswald font-semibold text-gray-400 tracking-wider uppercase">Courses</h3>
              <ul className="mt-4 space-y-4 uppercase">
                {navigation.courses.map((item) => (
                  <li key={item.name}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.href}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-1">
              <h3 className="text-lg oswald font-semibold text-gray-400 tracking-wider uppercase">Additional</h3>
              <ul className="mt-4 space-y-4 uppercase">
                {navigation.additional.map((item) => (
                  <li key={item.name}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.href}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              <ul className="flex mt-8 space-x-4 items-center uppercase">
                {navigation.social.map((item) => (
                  <li key={item.name}>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={item.href}
                      className="text-base text-gray-300 hover:text-white"
                    >
                      {item.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2" />
            <p className="uppercase oswald mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; {new Date().getFullYear()} OPEX FITNESS. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      <ExitIntent />
    </>
  );
}
