import React, { Fragment, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import html2canvas from 'html2canvas';

export default function SignatureInput({ open, closeHandler, saveSignature }) {
  const sigPag = useRef(null);
  const [signatureText, setSignatureText] = useState('');
  const cancelButtonRef = useRef(null);
  const [tab, setTab] = useState('signature');
  const typePad = useRef(null);
  const typeSig = useRef(null);

  const updateSig = () => {
    const ctx = typePad.current.getContext('2d');
    ctx.font = '32px Great Vibes';
    ctx.fillText(typeSig.current.value, 10, 50);
    setSignatureText(typeSig.current.value);
  };

  const trimCanvas = (canvas, threshold = 0) => {
    const ctx = canvas.getContext('2d');
    const w = canvas.width;
    const h = canvas.height;
    const imageData = ctx.getImageData(0, 0, w, h);
    const tlCorner = { x: w + 1, y: h + 1 };
    const brCorner = { x: -1, y: -1 };

    for (let y = 0; y < h; y++) {
      for (let x = 0; x < w; x++) {
        if (imageData.data[(y * w + x) * 4 + 3] > threshold) {
          tlCorner.x = Math.min(x, tlCorner.x);
          tlCorner.y = Math.min(y, tlCorner.y);
          brCorner.x = Math.max(x, brCorner.x);
          brCorner.y = Math.max(y, brCorner.y);
        }
      }
    }

    const cut = ctx.getImageData(tlCorner.x, tlCorner.y, brCorner.x - tlCorner.x, brCorner.y - tlCorner.y);

    canvas.width = brCorner.x - tlCorner.x;
    canvas.height = brCorner.y - tlCorner.y;

    ctx.putImageData(cut, 0, 0);

    return { width: canvas.width, height: canvas.height, x: tlCorner.x, y: tlCorner.y };
  };

  const clear = () => {
    if (sigPag.current) {
      sigPag.current.clear();
    }
    if (typePad.current) {
      typePad.current.clear();
    }
  };

  const save = () => {
    let sigImg = null;
    if (tab === 'signature') {
      sigImg = sigPag.current.getTrimmedCanvas().toDataURL('image/png');
      saveSignature(sigImg);
    } else {
      const element = document.getElementById('typed-signature');
      html2canvas(element).then((canvas) => {
        sigImg = canvas.toDataURL('image/png');
        saveSignature(sigImg);
      });
    }
  };

  const close = () => {
    if (sigPag.current) {
      sigPag.current.clear();
    }
    if (typePad.current) {
      typePad.current.clear();
    }
    closeHandler();
  };

  return ReactDom.createPortal(
    <div className="modal-container">
      <div className="modal">
        <div className="flex justify-center gap-4 py-2">
          <button
            type="button"
            className="bg-gray-300 text-gray-700 p-2 rounded-md"
            onClick={() => setTab('signature')}
          >
            Add Signature
          </button>
          <button type="button" className="bg-gray-300 text-gray-700 p-2 rounded-md" onClick={() => setTab('type')}>
            Type Signature
          </button>
        </div>
        <div className="sigContainer">
          {tab === 'signature' && <SignaturePad canvasProps={{ className: 'sigPad' }} ref={sigPag} />}

          {tab === 'type' && (
            <>
              <div id="typed-signature" style={{ fontSize: '96px' }} className="text-gray-800 sigPad handwritten">
                <span style={{ lineHeight: '96px' }}>{signatureText}</span>
              </div>
              <canvas className="hidden sigPad handwritten" ref={typePad} />
            </>
          )}
        </div>
        {tab === 'type' && (
          <div>
            <input className="text-gray-800" type="text" onChange={updateSig} ref={typeSig} />
          </div>
        )}
        <div className="flex justify-between p-3">
          <button type="button" className="bg-gray-300 text-gray-700 p-2 rounded-md" onClick={save}>
            Save
          </button>
          <button type="button" className="bg-gray-300 text-gray-700 p-2 rounded-md" onClick={clear}>
            Clear
          </button>
          <button type="button" className="bg-gray-300 text-gray-700 p-2 rounded-md" onClick={close}>
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}

SignatureInput.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  saveSignature: PropTypes.func
};
