/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import ReactDom from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../helpers/axios-instance';

export default function LoginModal({ open, onClose }) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const { loginUser, error } = useAuth();
  const history = useHistory();
  const {
    register,
    reset,
    control,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm();

  function handleOnChange(event) {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  }

  function forgotPasswordHandler(data) {
    axiosInstance
      .post('/reset-password', {
        email: data.email
      })
      .then((response) => {
        reset({
          email: ''
        });
        setForgotPasswordSuccess('Email has been sent. Please check your inbox');
      })
      .catch((error) => {
        setForgotPasswordError(error.response.data.error);
      });
  }

  let content = null;
  if (showForgotPassword) {
    content = (<div className="inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-sm w-full sm:p-6">
    <div>
      <div className="mt-3 text-center">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-300">
          Forgot Password
        </Dialog.Title>
      </div>
    </div>
    <div className="mt-5 sm:mt-6">
      <form onSubmit={handleSubmit(forgotPasswordHandler)} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-300">
            Email address
            <div className="mt-1">
              <input
                {...register('email', { required: true })}
                type="text"
                name="email"
                id="email"
                className="mt-1 block text-gray-800 w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
              />
              <p className="text-red-500">{errors.email?.type === 'required' && 'Email is required'}</p>
              {forgotPasswordError && (
                <p className="text-red-500">{forgotPasswordError}</p>
              )}
              {forgotPasswordSuccess && (
                <p className="text-green-500">{forgotPasswordSuccess}</p>
              )}
            </div>
          </label>
        </div>

        <div>
          <button
            type="submit"
            className="uppercase w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-opex hover:bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-opex"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>)
  } else {
    content = (
      <div className="inline-block bg-black rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-sm w-full sm:p-6">
              <div>
                <div className="mt-3 text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-300">
                    Login
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <form onSubmit={(e) => loginUser(e, history, user)} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                      Email address
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          onChange={handleOnChange}
                          value={user.email}
                          className="text-gray-700 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-opex focus:border-blue-opex sm:text-sm"
                        />
                      </div>
                    </label>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                      Password
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          onChange={handleOnChange}
                          value={user.password}
                          className="text-gray-700 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-opex focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </label>
                  </div>

                  {error &&(
                    <div className="mt-2">
                      <p className="text-sm text-red-500">{error}</p>
                    </div>
                  )}

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-300">
                        <input
                          id="remember_me"
                          name="remember_me"
                          type="checkbox"
                          className="h-4 w-4 text-blue-darker focus:ring-blue-opex border-gray-300 rounded mr-1"
                        />
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <button type="button" onClick={() => setShowForgotPassword(true)} className="font-medium text-blue-opex hover:text-blue-darker">
                        Forgot your password?
                      </button>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="uppercase w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-opex hover:bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-opex"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
    )
  }

  return ReactDom.createPortal(
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={() => {
        onClose(); setShowForgotPassword(false);}}>
        <div className="items-end justify-center min-h-screen text-center block p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block align-middle h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {content}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    document.body
  );
}

LoginModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
