import React, { useContext, useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useIntercom } from 'react-use-intercom';
import axiosInstance from '../helpers/axios-instance';
import Authenticate from '../helpers/authenticate';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [showBanner, setShowBanner] = useState(true);
  const [error, setError] = useState(null);
  const { boot, shutdown } = useIntercom();

  const authenticateUser = () => {
    Authenticate()
      .then((response) => {
        if (response.isAuth) {
          setIsAuth(response.isAuth);
          setUser(response.user);
          boot(response.user);
        } else {
          setIsAuth(false);
        }
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  const loginUser = (event, history, user) => {
    event.preventDefault();
    axiosInstance
      .post(`/sessions`, {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        setIsAuth(response.data.logged_in);
        setUser(response.data.user);
        boot(response.data.user);
        window.location.reload();
        // history.push('/homepage');
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  };

  const signupUser = (history, user) => {
    const code = Cookies.get('referral_code');
    axiosInstance
      .post(`/registrations`, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        password: user.password,
        referral_code: code,
      })
      .then((response) => {
        setIsAuth(response.data.logged_in);
        setUser(response.data.user);
        history.push('/homepage');
      })
      .catch((err) => console.log(err));
  };

  const logout = (history) => {
    axiosInstance
      .delete(`/logout`)
      .then(() => {
        setIsAuth(false);
        setUser(null);
        shutdown();
        history.push('/');
      })
      .catch((err) => console.log(err));
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        loginUser,
        logout,
        signupUser,
        setShowBanner,
        setIsAuth,
        setUser,
        showBanner,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
