import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }
  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <form className="w-full">
      <div className="flex gap-4">
        <input
          value={copyText}
          className="focus:ring-blue-opex focus:outline-none appearance-none outline-none border border-gray-700 flex-grow rounded-md bg-gray-900 text-gray-300 px-4 py-2 "
          type="text"
          readOnly
        />
        <button onClick={handleCopyClick} className="bg-blue-opex px-6 py-2 rounded-md uppercase" type="button">{isCopied ? 'Copied!' : 'Copy Link'}</button>
      </div>
    </form>
  )
}

ClipboardCopy.propTypes = {
  copyText: PropTypes.string
}