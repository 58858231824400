import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
  MinusIcon,
} from '@heroicons/react/solid';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Carousel from '../carousel';
import axiosInstance from '../../helpers/axios-instance';
import { useAuth } from '../../context/AuthContext';
import CcpPopup from '../ccp-popup';
import DowngradePopup from '../downgrade-popup';
import Upgrade from './upgrade';
import PauseSubscription from '../pause-subscription';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

const tiers = [
  {
    name: 'Coaching Certificate Program',
    plan: 'ccp',
    href: 'https://www.opexfit.com/ccp-level-1',
    description:
      'Become a Certified OPEX Coach, learn our proven methodology, and efficiently create personalized fitness, nutrition, and lifestyle programs that deliver long-term results.',
  },
  {
    name: 'Premium',
    plan: 'premium',
    priceMonthly: 399,
    description:
      'Access our ever-expanding library of high-quality fitness education classes taught by leading professionals.',
  },
  {
    name: 'Free',
    plan: 'free',
    priceMonthly: '0',
    description:
      "Enjoy our most popular free fitness education classes and a taste of what you'll learn as a premium member.",
  },
];

const mobileTiers = [
  {
    name: 'Premium',
    plan: 'premium',
    priceMonthly: 399,
    description:
      'Access our ever-expanding library of high-quality fitness education classes taught by leading professionals.',
  },
  {
    name: 'Coaching Certificate Program',
    plan: 'ccp',
    href: 'https://www.opexfit.com/ccp-level-1',
    description:
      'Become a Certified OPEX Coach, learn our proven methodology, and efficiently create personalized fitness, nutrition, and lifestyle programs that deliver long-term results.',
  },
  {
    name: 'Free',
    plan: 'free',
    priceMonthly: '0',
    description:
      "Enjoy our most popular free fitness education classes and a taste of what you'll learn as a premium member.",
  },
];
const sections = [
  {
    name: 'Features',
    features: [
      {
        name: 'Fitness Coaching Blueprint',
        tiers: { free: true, premium: true, ccp: true },
      },
      {
        name: 'Featured “OPEX Principles” Class',
        tiers: { free: true, premium: true, ccp: true },
      },
      {
        name: 'Entire LearnRx Class Library',
        tiers: { free: false, premium: true, ccp: true },
      },
      {
        name: 'New Content Releases Monthly',
        tiers: { free: false, premium: true, ccp: true },
      },
      {
        name: 'Class Resources',
        tiers: { free: false, premium: true, ccp: true },
      },
      {
        name: 'Community Forums',
        tiers: { free: false, premium: true, ccp: true },
      },
      {
        name: 'Become a Certified OPEX Coach',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'OPEX Method & Systems',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: '4 Course Textbooks',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: '300+ Course Videos',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'Weekly Cohort Mentor Calls',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'Exclusive Webinars & Immersions',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'CoachRx Software Access',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'Practical Client Case Studies',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'Instructor-Moderated Forums & Direct Support',
        tiers: { free: false, premium: false, ccp: true },
      },
      { name: 'CEUs', tiers: { free: false, premium: false, ccp: true } },
      {
        name: 'Library & Resources',
        tiers: { free: false, premium: false, ccp: true },
      },
      {
        name: 'Ambassador, Gym & Instructor Opportunities',
        tiers: { free: false, premium: false, ccp: true },
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function EditSubsctiption() {
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showCcpPopup, setShowCcpPopup] = useState(false);
  const [showDowngrade, setShowDowngrade] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [signupForm, setSignupForm] = useState({});
  const [showFeatures, setShowFeatures] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const { isAuth, user, setUser } = useAuth();
  const history = useHistory();

  async function getRecentlyAdded() {
    try {
      const response = await axiosInstance.get('/recently-added');
      setRecentlyAdded(response.data.recentlyAdded);
    } catch (error) {
      console.error(error);
    }
  }

  async function cancelSubscription() {
    try {
      const response = await axiosInstance.delete(`/subscriptions/cancel`);
      setUser(response.data.user);
      setShowDowngrade(false);
    } catch (error) {}
  }

  async function pauseSubscription(length) {
    try {
      const response = await axiosInstance.patch(`/subscriptions/pause`, {
        length,
      });
      setUser(response.data.user);
      setShowPause(false);
      history.push('/account/billing');
    } catch (error) {}
  }

  useEffect(() => {
    document.title = 'LearnRx - OPEX Fitness';
    getRecentlyAdded();
  }, []);

  const cancelHandler = () => {
    cancelSubscription();
    history.push('/account/billing');
    console.log('cancel subscription');
  };

  const pauseHandler = (length) => {
    pauseSubscription(length);

    console.log('cancel subscription');
  };

  let premiumButton = null;

  if (user?.hasMembership) {
    premiumButton = (
      <button
        type='button'
        onClick={() => {
          setShowPause(true);
        }}
        className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md border-blue-opex hover:bg-blue-darker'
      >
        {user?.cancelsAt ? 'Subscription Canceled' : 'Pause Subscription'}
      </button>
    );
  } else if (user?.paused_membership) {
    premiumButton = (
      <button
        type='button'
        onClick={() => {
          console.log('paused');
        }}
        className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md border-blue-opex hover:bg-blue-darker'
      >
        Membership Paused
      </button>
    );
  } else {
    premiumButton = (
      <button
        type='button'
        onClick={() => {
          setShowUpgrade(true);
        }}
        className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md border-blue-opex hover:bg-blue-darker'
      >
        Upgrade Subscription
      </button>
    );
  }

  return (
    <div>
      <div className='bg-gray-600'>
        <div className='p-4 mx-auto max-w-7xl md:p-8'>
          <h1 className='text-3xl font-light text-white uppercase oswald sm:text-5xl sm:tracking-tight'>
            Edit <span className='font-extrabold'>Subscription</span>
          </h1>
        </div>
      </div>
      <div className='py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8'>
        <div className='hidden mb-8 lg:block'>
          <div className='flex w-full'>
            <div className='flex flex-col w-1/4 border-2 border-transparent'>
              <div className='px-6 py-4 text-lg font-medium leading-6 text-left text-gray-300 border-b border-gray-700'>
                <span>Plans</span>
              </div>
              <div className='px-6 py-8 text-sm font-medium text-left text-gray-300 align-top'>
                Pricing
              </div>
            </div>
            <div className='flex flex-col w-1/4 border-2 border-transparent rounded-md'>
              <div className='px-6 py-4 text-lg font-medium leading-6 text-left text-gray-300 border-b border-gray-700'>
                <span>{tiers[0].name}</span>
              </div>
              <div className='px-6 py-8 text-sm font-medium text-left text-gray-300 align-top'>
                {tiers[0].priceMonthly ? (
                  <>
                    <span className='text-4xl font-extrabold text-gray-300'>
                      ${tiers[0].priceMonthly}
                    </span>{' '}
                    <span className='text-base font-medium text-gray-400'>
                      /mo
                    </span>
                  </>
                ) : (
                  <span
                    style={{ lineHeight: '39px' }}
                    className='text-3xl font-extrabold text-gray-300'
                  >
                    Contact Advisor
                  </span>
                )}
                <p className='h-24 mt-4 mb-16 text-sm text-gray-300'>
                  {tiers[0].description}
                </p>
                {tiers[0].priceMonthly ? (
                  <button
                    type='button'
                    onClick={() => {
                      setSignupForm({
                        ...signupForm,
                        plan: tiers[0].plan,
                      });
                      setShowSignupForm(true);
                    }}
                    className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md border-blue-opex hover:bg-blue-darker'
                  >
                    Sign up for {tiers[0].name}
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={() => setShowCcpPopup(true)}
                    className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md oswald border-blue-opex hover:bg-blue-darker'
                  >
                    Become a CCP Coach
                  </button>
                )}
              </div>
            </div>
            {/* Premium Tier */}
            <div
              className={`border-2 ${
                user?.hasMembership ? 'border-blue-opex' : 'border-transparent'
              } w-1/4 flex flex-col rounded-md`}
            >
              <div className='px-6 py-4 text-lg font-medium leading-6 text-left text-gray-300 border-b border-gray-700'>
                <span>{tiers[1].name}</span>
              </div>
              <div className='px-6 py-8 text-sm font-medium text-left text-gray-300 align-top'>
                <>
                  <span className='text-4xl font-extrabold text-gray-300'>
                    ${tiers[1].priceMonthly}
                  </span>{' '}
                  <span className='text-base font-medium text-gray-400'>
                    /year
                  </span>
                </>
                <p className='h-24 mt-4 mb-16 text-sm text-gray-300'>
                  {tiers[1].description}
                </p>
                {premiumButton}
              </div>
            </div>
            {/* Free Tier */}
            <div
              className={`border-2 ${
                !user?.hasMembership ? 'border-blue-opex' : 'border-transparent'
              } w-1/4 flex flex-col rounded-md`}
            >
              <div className='px-6 py-4 text-lg font-medium leading-6 text-left text-gray-300 border-b border-gray-700'>
                <span>{tiers[2].name}</span>
              </div>
              <div className='px-6 py-8 text-sm font-medium text-left text-gray-300 align-top'>
                <>
                  <span className='text-4xl font-extrabold text-gray-300'>
                    ${tiers[2].priceMonthly}
                  </span>{' '}
                  <span className='text-base font-medium text-gray-400'>
                    /mo
                  </span>
                </>
                <p className='h-24 mt-4 mb-16 text-sm text-gray-300'>
                  {tiers[2].description}
                </p>
                {user?.hasMembership ? (
                  <button
                    type='button'
                    onClick={() => {
                      setShowDowngrade(true);
                    }}
                    className='block w-full py-2 mt-3 text-sm font-semibold text-center text-white uppercase bg-transparent border rounded-md border-blue-opex hover:bg-blue-darker'
                  >
                    Downgrade to Free
                  </button>
                ) : (
                  <div className='block w-full py-2 mt-3 text-sm font-semibold text-center text-gray-900 uppercase bg-transparent border border-transparent rounded-md'>
                    Current Option
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='py-3 pl-6 mt-6 text-sm font-medium text-left text-gray-300 bg-gray-800 border border-gray-700 rounded-md'>
            <div className='flex items-center px-6'>
              Features{' '}
              <button
                onClick={() => setShowFeatures(!showFeatures)}
                type='button'
              >
                <ChevronDownIcon
                  className={`transition duration-500 ease-in-out ml-3 w-6 h-6 text-blue-opex ${
                    showFeatures ? 'rotate-180' : ''
                  }`}
                />
              </button>
            </div>
            {showFeatures && (
              <div>
                <table className='w-full h-px table-fixed'>
                  <caption className='sr-only'>Pricing plan comparison</caption>
                  <thead>
                    <tr>
                      <th
                        className='px-6 pb-4 text-sm font-medium text-left text-gray-300'
                        scope='col'
                      />
                      {tiers.map((tier) => (
                        <th
                          key={tier.name}
                          className='w-1/4 px-6 pb-4 text-lg font-medium leading-6 text-left text-gray-300'
                          scope='col'
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody className='border-t border-gray-600 divide-y divide-gray-600'>
                    {sections.map((section) => (
                      <Fragment key={section.name}>
                        {section.features.map((feature) => (
                          <tr key={feature.name}>
                            <th
                              className='px-6 py-5 text-sm font-normal text-left text-gray-300'
                              scope='row'
                            >
                              {feature.name}
                            </th>
                            {tiers.map((tier) => (
                              <td key={tier.name} className='px-6 py-5'>
                                {typeof feature.tiers[tier.plan] ===
                                'string' ? (
                                  <span className='block text-sm text-gray-300'>
                                    {feature.tiers[tier.plan]}
                                  </span>
                                ) : (
                                  <>
                                    {feature.tiers[tier.plan] === true ? (
                                      <CheckIcon
                                        className='w-5 h-5 text-green-500'
                                        aria-hidden='true'
                                      />
                                    ) : (
                                      <MinusIcon
                                        className='w-5 h-5 text-gray-400'
                                        aria-hidden='true'
                                      />
                                    )}

                                    <span className='sr-only'>
                                      {feature.tiers[tier.name] === true
                                        ? 'Included'
                                        : 'Not included'}{' '}
                                      in {tier.name}
                                    </span>
                                  </>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <Carousel
          noSpacing
          classes={recentlyAdded}
          title='Recently Added'
          type='class'
        />
      </div>
      <CcpPopup open={showCcpPopup} onClose={() => setShowCcpPopup(false)} />
      <DowngradePopup
        downgradeHandler={cancelHandler}
        open={showDowngrade}
        onClose={() => setShowDowngrade(false)}
      />
      <Elements stripe={stripePromise}>
        <Upgrade open={showUpgrade} onClose={() => setShowUpgrade(false)} />
      </Elements>
      <PauseSubscription
        pauseHandler={pauseHandler}
        onClose={() => setShowPause(false)}
        open={showPause}
      />
    </div>
  );
}
