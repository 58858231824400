import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navigation() {
  return (
    <nav className='account-nav md:w-1/4 md:pr-8'>
      <NavLink
        to='/account/edit'
        className='mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150'
        href='/users/edit'
      >
        <i
          className='fal fa-user-circle flex-shrink-0 mr-3 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150'
          aria-hidden='true'
        />
        <span className='truncate'>Account</span>
      </NavLink>
      <NavLink
        to='/account/change-password'
        className='mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150'
        href='/settings/password/edit'
      >
        <i
          className='fal fa-key flex-shrink-0 mr-3 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150'
          aria-hidden='true'
        />
        <span className='truncate'>Password</span>
      </NavLink>
      <NavLink
        to='/account/billing'
        className='mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150'
        href='/subscription'
      >
        <i
          className='fal fa-credit-card flex-shrink-0 mr-3 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150'
          aria-hidden='true'
        />
        <span className='truncate'>Subscriptions</span>
      </NavLink>
      <NavLink
        to='/account/contracts'
        className='mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150'
      >
        <i
          className='fal fa-credit-card flex-shrink-0 mr-3 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150'
          aria-hidden='true'
        />
        <span className='truncate'>Contracts</span>
      </NavLink>
      <NavLink
        to='/account/referrals'
        className='mt-1 group flex items-center px-3 py-2 text-sm leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150'
        href='/subscription'
      >
        <i
          className='fal fa-credit-card flex-shrink-0 mr-3 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150'
          aria-hidden='true'
        />
        <span className='truncate'>Referrals</span>
      </NavLink>
    </nav>
  );
}
