import React from 'react';
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import EditAccount from '../components/account/edit-account';
import Billing from '../components/account/billing';
import ChangePassword from '../components/account/password';
import FooterCta from '../components/footer-cta';
import Referrals from '../components/account/referrals';
import EditSubsctiption from '../components/account/edit-subscription';
import Contracts from '../components/account/contracts';
import Contract from '../components/account/contract';

export default function Account() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Header />
      <Switch>
        <Route exact path={`${path}/edit`}>
          <EditAccount />
        </Route>
        <Route exact path={`${path}/billing`}>
          <Billing />
        </Route>
        <Route exact path={`${path}/change-password`}>
          <ChangePassword />
        </Route>
        <Route exact path={`${path}/referrals`}>
          <Referrals />
        </Route>
        <Route exact path={`${path}/edit-subscription`}>
          <EditSubsctiption />
        </Route>
        <Route exact path={`${path}/contracts`}>
          <Contracts />
        </Route>
        <Route exact path={`${path}/contracts/:id`}>
          <Contract />
        </Route>
      </Switch>
      <FooterCta />
      <Footer />
    </div>
  );
}
